<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="320"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form
      @submit.prevent="execute"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--body">
          <h3>Devolver chamado</h3>

          <v-textarea
            id="message"
            name="message"
            label="Digite uma justificativa"
            hide-details
            outlined
            dense
            required
            :rules="[required]"
            v-model="message"
          />
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="primary"
            title="Voltar"
            @click="closeModal(false)"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Devolver"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    ticketId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    message: null,
    valid: true
  }),

  methods: {
    closeModal(redirect) {
      this.$emit('closeModal', redirect);
    },

    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const supportService = new SupportService();
        const { status } = await supportService.postTicketReturn({
          ticketId: this.ticketId,
          message: this.message
        });

        if (status === 204) {
          showMessage('success', 'Chamado devolvido com sucesso!');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
